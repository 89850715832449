import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Injectable()
export class LanguageService {

    private readonly _defaultLanguage: string = 'en';

    constructor(private _translateService: TranslateService,
                private _cookieService: NgcCookieConsentService) {
    }

    public bootstrap(): void {
        const language = this.getDeviceLanguage();
        this._translateService.setDefaultLang(language);
        this._translateService.use(language);

        window.localStorage.setItem("oss_language", language.toUpperCase());

        this.translateCookiesNotification();
    }

    public getDeviceLanguage(): string {

        return this._defaultLanguage;

        // uncomment if multiple languages is supported
        // const deviceLanguage = navigator.language.split('-')[0];
        //
        // if (/(nl)/gi.test(deviceLanguage)) {
        //     return deviceLanguage.toLowerCase();
        // }
    }

    // Added: July 9, 2019
    public setLanguage = (language: string) => {
        this._translateService.setDefaultLang(language.toLowerCase());
        this._translateService.use(language.toLowerCase());

        window.localStorage.setItem("oss_language", language);

        //this.translateCookiesNotification();

        if (localStorage.getItem("cookiesconsent_on") != "yes")
        {
            this.translateCookiesNotification();
        }
    }

    translateCookiesNotification = () => {

        this._translateService
            .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
            .subscribe(data => {

                this._cookieService.getConfig().content = this._cookieService.getConfig().content || {},

                this._cookieService.getConfig().content.header = data['cookie.header'];
                this._cookieService.getConfig().content.message = data['cookie.message'];
                this._cookieService.getConfig().content.dismiss = data['cookie.dismiss'];
                this._cookieService.getConfig().content.allow = data['cookie.allow'];
                this._cookieService.getConfig().content.deny = data['cookie.deny'];
                this._cookieService.getConfig().content.link = data['cookie.link'];
                this._cookieService.getConfig().content.policy = data['cookie.policy'];

                this._cookieService.destroy();
                this._cookieService.init(this._cookieService.getConfig());
            });
    }
}
