import { FILTER_ACTIONS } from './filter.actions';

export interface IFilterState {
    selectedAreaFilters: string[];
    selectedClientTypeFilters: string[];
    selectedBudgetFilter: { min: number, max: number };
    availableAreaFilterOptions: string[];
    availableClienTypeFilters: string[];
    availbleBudgetFilter: { min: number, max: number };
}

export function filterReducer(state = FILTER_INITIAL_STATE, action): IFilterState {
    switch (action.type) {
        case FILTER_ACTIONS.SET_AVAILABLE_AREA_FILTER_OPTIONS:
            return setAvailbleAreaFilters(state, action);
        case FILTER_ACTIONS.SET_AVAILABLE_CLIENT_TYPE_FILTER_OPTIONS:
            return setAvailbleClientTypeFilters(state, action);
        case FILTER_ACTIONS.SET_SELECTED_AREA_FILTER:
            return setAreaFilter(state, action);
        case FILTER_ACTIONS.SET_SELECTED_CLIENT_TYPE_FILTER:
            return setClientTypeFilters(state, action);
        case FILTER_ACTIONS.SET_SELECTED_BUDGET_FILTER:
            return setBudgetFilter(state, action);
        case FILTER_ACTIONS.CLEAR_SELECTED_FILTERS:
            return clearSelectedFilters(state, action);
        case FILTER_ACTIONS.SET_BOUNDS_BUDGET_FILTER:
            return setBoundsBudgetFilter(state, action);

    }

    return state;
}

function clearSelectedFilters(state, action) {
    return Object.assign({}, state, {
        selectedAreaFilters: [],
        selectedClientTypeFilters: [],
        selectedBudgetFilter: { ...state.availbleBudgetFilter }
    });
}

function setBoundsBudgetFilter(state, action) {
    return Object.assign({}, state, { availbleBudgetFilter: action.filter });
}

function setBudgetFilter(state, action) {
    if (!action.filter) {
        action.filter = { ...state.availbleBudgetFilter };
    }
    return Object.assign({}, state, { selectedBudgetFilter: action.filter });

}

function setAvailbleAreaFilters(state, action) {
    return Object.assign({}, state, { availableAreaFilterOptions: action.filters });
}

function setAvailbleClientTypeFilters(state, action) {
    return Object.assign({}, state, { availableClienTypeFilters: action.filters });
}

function setAreaFilter(state: IFilterState, action) {
    const copiedFilters: string[] = [...state.selectedAreaFilters];
    const index = copiedFilters.indexOf(action.filter);
    // found and remove
    let temp;
    action.add ?
        (index === -1 ? copiedFilters.push(action.filter) : temp = state) :
        (index !== -1 ? copiedFilters.splice(index, 1) : temp = state);
    return Object.assign({}, state, { selectedAreaFilters: copiedFilters });

}

function setClientTypeFilters(state, action) {
    const copiedFilters: string[] = [...state.selectedClientTypeFilters];
    const index = copiedFilters.indexOf(action.filter);
    let temp;
    action.add ?
        (index === -1 ? copiedFilters.push(action.filter) : temp = state) :
        (index !== -1 ? copiedFilters.splice(index, 1) : temp = state);
    return Object.assign({}, state, { selectedClientTypeFilters: copiedFilters });
}

export const FILTER_INITIAL_STATE: IFilterState = {
    selectedAreaFilters: [],
    selectedClientTypeFilters: [],
    selectedBudgetFilter: { min: 0, max: 0 },
    availableAreaFilterOptions: [],
    availableClienTypeFilters: [],
    availbleBudgetFilter: { min: 0, max: 0 }
};
