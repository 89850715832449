import { Component, Input, OnInit } from '@angular/core';
import { Expertise } from '../../../../domain/expertise/expertise.domain';
import { ExpertiseAttachment } from '../../../../domain/attachment/attachment.domain';

@Component({
    selector: 'app-expertise-documents',
    templateUrl: './expertise-documents.component.html',
    styleUrls: ['./expertise-documents.component.scss']
})
export class ExpertiseDocumentsComponent implements OnInit {
    @Input() expertise: Expertise;

    constructor() {
    }

    openDocument = (document: ExpertiseAttachment) => {
        window.open(document.fullUrl, '_blank');
    };

    ngOnInit() {
    }

}
