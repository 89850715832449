import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';

@Component({
    selector: 'app-expertise-location-map',
    templateUrl: './expertise-location-map.component.html',
    styleUrls: ['./expertise-location-map.component.scss']
})
export class ExpertiseLocationMapComponent implements OnInit {

    constructor() {
    }

    map: L.map;
    @Input() coordinates: [number, number];

    ngOnInit() {
        this.map = L.map('map', {
            center: this.coordinates,
            zoom: 16,
            attributionControl: false,
            zoomControl: false,
            dragging: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            touchZoom: false

        });

        const osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        const osm = new L.TileLayer(osmUrl, {});

        this.map.addLayer(osm);
    }

}
