import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { select } from '@angular-redux/store';
import { FILTER_TYPE } from '../../constants/filterTypes.constant';
import { Expertise } from '../../domain/expertise/expertise.domain';
import { NavigationService } from '../../services/navigation/navigation.service';
import { NAVIGATION_KEYS } from '../../constants/navigation.constant';
import { DetailPageComponent } from '../detail-page/detail-page.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FilterService } from '../../services/filter/filter.service';

@Component({
    selector: 'app-results-page',
    templateUrl: './results-page.component.html',
    styleUrls: ['./results-page.component.scss']
})
export class ResultsPageComponent implements OnInit, OnDestroy {

    @select(['searchReducer', 'searchResults']) searchResults: Observable<Expertise[]>;
    @select(['searchReducer', 'searchQuery']) searchQuery: Observable<string>;
    @select(['searchReducer', 'searchCategory']) searchCategory: BehaviorSubject<string>;
    @select(['searchReducer', 'searchActivityUnit']) searchActivityUnit: BehaviorSubject<string>;
    filterTypes = FILTER_TYPE;
    filteredSearchResults: BehaviorSubject<Expertise[]> = new BehaviorSubject<Expertise[]>([]);

    private subscription: Subscription;

    constructor(private _navigationService: NavigationService,
                private _filterService: FilterService) {
    }

    ngOnInit() {
        window.scrollTo(0, 0);
        this.filteredSearchResults = this._filterService.filteredSearchResults;
    }

    expertiseSelected = (expertise: Expertise) => {
        this._navigationService.navigate(NAVIGATION_KEYS.detail,
            { queryParams: { [DetailPageComponent.expertiseId]: expertise.ID } });
    };

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    //Event when resizing browser to check the width
    @HostListener('window:resize', ['$event'])
    onresize(event)
    {
        //console.log('width: ' + window.innerWidth);
    }
}
