export class Message {
    emailCompany: string;
    submitterName: string;
    emailAddress: string;
    subject: string;
    message: string;

    constructor(data: any = {}) {
        this.emailCompany = data.EmailCompany ? data.EmailCompany : '';
        this.submitterName = data.SubmitterName ? data.SubmitterName : '';
        this.emailAddress = data.EmailAddress ? data.EmailAddress : '';
        this.subject = data.Subject ? data.Subject : '';
        this.message = data.Message ? data.Message : '';
    }

    getMessageJSON = () => {
        return {
            'EmailCompany': this.emailCompany,
            'SubmitterName': this.submitterName,
            'EmailAddress': this.emailAddress,
            'Subject': this.subject,
            'Message': this.message
        };
    };
}
