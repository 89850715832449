import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../../store/IAppState';
import { SEARCH_ACTIONS } from '../../../../store/reducers/search/search.actions';
import { SearchService } from '../../../services/search/search.service';
import { Observable } from 'rxjs';
import { Expertise } from '../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

    @select(['searchReducer', 'expertises']) expertises: Observable<Expertise[]>;
    @select(['searchReducer', 'searchQuery']) searchQuery: Observable<string>;
    windowHeight: number;
    windowWidth: number;
    topOffset = '50%';
    rightOffset = '50%';
    labelOpacity = 1;
    searchSize;
    initialFactor;

    query = '';
    private searchBarElement: HTMLElement;

    onHomePage = false;

    constructor(private router: Router,
                private _searchService: SearchService,
                private _ngRedux: NgRedux<IAppState>) {
        window.onresize = () => {
            this.calculateSizes();
        };
    }

    doSearch = (e) => {

        if (e.length < 3) {
            return;
        }
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_QUERY, query: this.query });
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_CATEGORY, category: '' });
        this.searchBarElement.blur();
        this._searchService.findExpertisesBySearchQuery();

    };

    ngOnInit(): void {
        this.calculateSizes();
        this.checkScroll();
        this.router.events.subscribe(e => {
            this.checkScroll();
        });
        this.searchBarElement = document.getElementById('search');
        this.searchQuery.subscribe(e => {
            if (!e) {
                setTimeout(() => {
                    // implemented to prevent expression changed error
                    this.query = '';
                }, 200);
            }
        });
    }

    @HostListener('window:scroll', [])
    checkScroll() {
        const windowHeightDevider = 2.6;
        const factor = (window.pageYOffset * this.initialFactor) / (this.windowHeight / windowHeightDevider);
        let value = this.initialFactor + this.searchSize / 2 - factor;
        let topOffsetHelper = 0;
        if ((window.pageYOffset < this.windowHeight / windowHeightDevider - 47) && (this.router.url === '/')) {
            topOffsetHelper = this.windowHeight / windowHeightDevider - window.pageYOffset;

            if (value < this.searchSize / 2 + 20) {
                value = this.searchSize / 2 + 20;
            }
            this.rightOffset = value + 'px';
        } else {
            topOffsetHelper = 47;
            this.rightOffset = this.searchSize / 2 + 20 + 'px';
            value = this.searchSize / 2 + 20;
        }

        this.topOffset = topOffsetHelper + 'px';
        this.labelOpacity = (topOffsetHelper / 47) - 1;
    }

    calculateSizes() {
        if (!document.getElementById('search')) {
            return;
        }

        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this.searchSize = document.getElementById('search').offsetWidth;
        this.initialFactor = (this.windowWidth / 2 - this.searchSize / 2);
        this.checkScroll();
    }

}
