import { Component, OnInit } from '@angular/core';
import { STATIC_CATEGORIES } from '../../constants/categories.constant';
import { SearchService } from '../../services/search/search.service';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../store/IAppState';
import { SEARCH_ACTIONS } from '../../../store/reducers/search/search.actions';
import { Observable } from 'rxjs';
import { FilterService } from '../../services/filter/filter.service';

@Component({
    selector: 'app-search-page',
    templateUrl: './search-page.component.html',
    styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {

    staticCategories = STATIC_CATEGORIES;
    selectedTab = 0;
    @select(['searchReducer', 'categories']) categories: Observable<string[]>;
    @select(['searchReducer', 'activityUnits']) activityUnits: Observable<string[]>;

    constructor(private _searchService: SearchService,
                private _filterService: FilterService,
                private _ngRedux: NgRedux<IAppState>) {

    }

    ngOnInit(): void {
        this._filterService.clearFilters();
        this._searchService.clearQuery();
    }

    searchByCategory = (category: string) => {
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_CATEGORY, category: category });
        this._searchService.findExpertisesByCategory();
    };
    activityUnitSelected = (activityUnit: string) => {
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_ACTIVITY_UNIT, searchActivityUnit: activityUnit });
        this._searchService.findExpertisesByActivityUnit();

    };
    showAll = () => {
        this._searchService.showAllExpertises();
    };
}



