import { Component, Input, OnInit } from '@angular/core';
import { Expertise } from '../../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-expertise-quick-info',
    templateUrl: './expertise-quick-info.component.html',
    styleUrls: ['./expertise-quick-info.component.scss']
})
export class ExpertiseQuickInfoComponent implements OnInit {

    @Input() expertise: Expertise;

    constructor() {
    }

    ngOnInit() {
    }

}
