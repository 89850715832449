import { Component, OnDestroy, OnInit, ReflectiveInjector } from '@angular/core';
import { LanguageService } from './services/language/language.service';
import { FilterService } from './services/filter/filter.service';
import { ExpertiseService } from './services/expertise/expertise.service';
import { Router } from '@angular/router';
import { NAVIGATION_KEYS } from './constants/navigation.constant';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { AppInsightsService } from './services/app-insights.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    loaderVisible = true;
    errorVisible = false;
    failedSubscription: any;
    successSubscription: any;
    authenticated = false;
    authFailed = false;
    language: String;

    constructor(private _languageService: LanguageService,
                private _filterService: FilterService,
                private _expertiseService: ExpertiseService,
                private router: Router,
                private appInsightsService: AppInsightsService,
                private broadcastService: BroadcastService,
                private msa: MsalService) {
    }

    onDetailPage = false;

    ngOnInit(): void {
        this.appInsightsService.logPageView('Home');

        this._languageService.bootstrap();

        this.router.events.subscribe(e => {
            this.onDetailPage = this.router.url.split('?')[0].includes(NAVIGATION_KEYS.detail);
        });

        /* if (this.msa.getUser()) {
            this.authenticated = true;
            this.fetchExpertises();
        } */

        this.fetchExpertises();

        this.failedSubscription = this.broadcastService.subscribe('msal:loginFailure', (payload) => this.authFailed = true);
        this.successSubscription = this.broadcastService.subscribe('msal:loginSuccess', (payload) => this.authFailed = false);
    }

    retryAuthentication = () => {
        this.msa.loginRedirect();
    };

    private fetchExpertises = () => {
        this.loaderVisible = true;
        this.errorVisible = false;
        this.getSavedLanguage();

        this._expertiseService.getExpertises().then((expertises) => {
            this._filterService.setFilters(expertises);
            this.loaderVisible = false;
        }).catch(e => {
            console.log(e);
            this.errorVisible = true;
        });
    };

    ngOnDestroy() {
        this.broadcastService.getMSALSubject().next(1);
        if (this.successSubscription) {
            this.successSubscription.unsubscribe();
        }
        if (this.failedSubscription) {
            this.failedSubscription.unsubscribe();
        }
    }

    resetPassword = (email: string) => {
        //try mailTo
        //sending of email make sure that sender comes from the real sender
        //confirmation?
    }

    getSavedLanguage = () => {
        this.language = window.localStorage.getItem("oss_language");

        if (this.language === null) {
            this.language = "en";
            window.localStorage.setItem("oss_language", "en");
        }
    }
}
