import { Injectable, OnInit } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../store/IAppState';
import { FILTER_ACTIONS } from '../../../store/reducers/filter/filter.actions';
import { Expertise } from '../../domain/expertise/expertise.domain';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FilterService implements OnInit {

    areaFilters = [];
    clienTypeFilters = [];

    selectedAreaFilter = [];
    selectedClientTypeFilters = [];
    budgetFilter;
    budgetBounds;
    @select(['filterReducer']) filterReducer;
    @select(['searchReducer', 'searchResults']) searchResults: Observable<Expertise[]>;

    private localSearchResults = [];
    filteredSearchResults: BehaviorSubject<Expertise[]> = new BehaviorSubject<Expertise[]>([]);

    constructor(private _ngRedux: NgRedux<IAppState>) {
    }

    ngOnInit(): void {

    }

    filterExpertises = () => {
        let filteredExpertises: Expertise[] = [...this.localSearchResults];
        if (this.selectedAreaFilter.length) {
            filteredExpertises = filteredExpertises
                .filter(expertise => new RegExp(this.selectedAreaFilter.join('|')).test(expertise.activityArea.join(' ')));
        }
        if (this.selectedClientTypeFilters.length) {
            filteredExpertises = filteredExpertises
                .filter(expertise => new RegExp(this.selectedClientTypeFilters.join('|')).test(expertise.clientType.join(' ')));
        }
        if (this.budgetFilter.min !== this.budgetBounds.min || this.budgetFilter.max !== this.budgetBounds.max) {
            filteredExpertises = filteredExpertises
                .filter(expertise => {
                    return (this.budgetFilter.min <= expertise.minProjectValue && expertise.maxProjectValue <= this.budgetFilter.max);
                });
        }
        this.filteredSearchResults.next(filteredExpertises);

        return filteredExpertises;
    };

    setFilters = (expertises: Expertise[]) => {
        this.setAvailbleAreaFilters(expertises);
        this.setAvailbleClientTypeFilters(expertises);
        this.setBudgetFilter(expertises);
        this.setSubscriptions();
    };
    setSubscriptions = () => {
        this.filterReducer.subscribe(filterReducer => {
            this.selectedAreaFilter = filterReducer.selectedAreaFilters;
            this.selectedClientTypeFilters = filterReducer.selectedClientTypeFilters;
            this.budgetFilter = filterReducer.selectedBudgetFilter;
            this.filterExpertises();
        });
        this.searchResults.subscribe(searchResults => {
            this.localSearchResults = searchResults;
            this.filterExpertises();
        });
    };

    clearFilters = () => {
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.CLEAR_SELECTED_FILTERS });
    };

    private setBudgetFilter = (expertises: Expertise[]) => {
        const budget = { min: null, max: null };
        for (const expertise of expertises) {
            if (!budget.min) {
                budget.min = expertise.minProjectValue;
            }
            if (!budget.max) {
                budget.max = expertise.maxProjectValue;
            }
            expertise.minProjectValue && (expertise.minProjectValue < budget.min) ? budget.min = expertise.minProjectValue : budget.min = budget.min;

            expertise.minProjectValue && (expertise.maxProjectValue > budget.max) ? budget.max = expertise.maxProjectValue : budget.max = budget.max;
        }

        this.budgetBounds = budget;
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_BOUNDS_BUDGET_FILTER, filter: budget });
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_SELECTED_BUDGET_FILTER, filter: budget });

    };
    private setAvailbleAreaFilters = (expertises: Expertise[]) => {

        let expertiseAreas: string[] = [];
        this.areaFilters = expertises
            .map(expertise => expertiseAreas = expertiseAreas.concat(expertise.activityArea));
        this.areaFilters = expertiseAreas.filter((el) => el.length).sort()
            .filter((el, i, a) => i === a.indexOf(el));
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_AVAILABLE_AREA_FILTER_OPTIONS, filters: this.areaFilters });
    };
    private setAvailbleClientTypeFilters = (expertises: Expertise[]) => {
        this.clienTypeFilters = [];
        for (const expertise of expertises) {
            this.clienTypeFilters = this.clienTypeFilters.concat(expertise.clientType
                .map(category => category.trim()))
                .filter((el) => el.length)
                .filter((el, i, a) => i === a.indexOf(el));
        }
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_AVAILABLE_CLIENT_TYPE_FILTER_OPTIONS, filters: this.clienTypeFilters });

    };
}
