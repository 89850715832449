import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { createTranslateLoader } from '../utils/language.util';
import { LanguageService } from './services/language/language.service';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { HeaderComponent } from './components/static/header/header.component';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { IAppState } from '../store/IAppState';
import { store } from '../store/store';
import { CategorySearchTabComponent } from './components/dynamic/category-search-tab/category-search-tab.component';
import { SearchBarComponent } from './components/static/search-bar/search-bar.component';
import { ResultsPageComponent } from './pages/results-page/results-page.component';
import { FilterSpinnerComponent } from './components/dynamic/filter-spinner/filter-spinner.component';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { CheckboxComponent } from './components/dynamic/checkbox/checkbox.component';
import { BudgetPickerComponent } from './components/dynamic/filters/budget-picker/budget-picker.component';
import { MobileFilterFlyoutComponent } from './components/dynamic/mobile-filter-flyout/mobile-filter-flyout.component';
import { AreaFilterComponent } from './components/dynamic/filters/area-filter/area-filter.component';
import { ClientTypeFilterComponent } from './components/dynamic/filters/client-type-filter/client-type-filter.component';
import { ExpertiseTabComponent } from './components/dynamic/expertise-tab/expertise-tab.component';
import { DetailPageComponent } from './pages/detail-page/detail-page.component';
import { ExpertiseInfoComponent } from './components/dynamic/detail-page-components/expertise-info/expertise-info.component';
import { ExpertiseContactComponent } from './components/dynamic/detail-page-components/expertise-contact/expertise-contact.component';
import { ExpertiseDocumentsComponent } from './components/dynamic/detail-page-components/expertise-documents/expertise-documents.component';
import { DesktopDetailPageIndexComponent } from './components/dynamic/desktop-detail-page-index/desktop-detail-page-index.component';
import { ContactDialogComponent } from './components/dynamic/contact-dialog/contact-dialog.component';
import { LoaderComponent } from './components/static/loader/loader.component';
import { ExpertiseLocationMapComponent } from './components/dynamic/expertise-location-map/expertise-location-map.component';
import { GenericLoaderComponent } from './components/dynamic/generic-loader/generic-loader.component';
import { ImageWithLoaderComponent } from './components/dynamic/image-with-loader/image-with-loader.component';
import { MsalModule } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { MobileDetailPageIndexFlyoutComponent } from './components/dynamic/mobile-detail-page-index-flyout/mobile-detail-page-index-flyout.component';
import { ExpertiseCertificatesComponent } from './components/dynamic/detail-page-components/expertise-certificates/expertise-certificates.component';
import { ExpertiseCompetitionComponent } from './components/dynamic/detail-page-components/expertise-competition/expertise-competition.component';
import { ExpertiseQuickInfoComponent } from './components/dynamic/detail-page-components/expertise-quick-info/expertise-quick-info.component';

import { AppInsightsInterceptorService } from './services/app-insights-interceptor.service';
import { NeedHelpComponent } from './components/dynamic/need-help/need-help.component';
import { HelpdeskDialogComponent } from './components/dynamic/helpdesk-dialog/helpdesk-dialog.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { text } from '@angular/core/src/render3/instructions';


const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
        //'localhost' or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
        //domain: 'https://oss.besix.com'
        domain: environment.cookieDomain
    },
    palette: {
        popup: {
            background: '#0080ff',
            text: "#ffffff",
            link: "#ffffff"
        },
        button: {
            background: '#f1d600',
            text: "#000000",
            border: "transparent"
        }
    },
    theme: 'classic',
    type: 'info',
}

@NgModule({
    declarations: [
        AppComponent,
        SearchPageComponent,
        HeaderComponent,
        CategorySearchTabComponent,
        SearchBarComponent,
        ResultsPageComponent,
        FilterSpinnerComponent,

        EnumToArrayPipe,

        CheckboxComponent,

        BudgetPickerComponent,

        MobileFilterFlyoutComponent,

        AreaFilterComponent,

        ClientTypeFilterComponent,

        ExpertiseTabComponent,

        DetailPageComponent,

        ExpertiseQuickInfoComponent,

        ExpertiseInfoComponent,

        ExpertiseContactComponent,

        ExpertiseCompetitionComponent,

        ExpertiseDocumentsComponent,

        ExpertiseCertificatesComponent,

        DesktopDetailPageIndexComponent,

        MobileDetailPageIndexFlyoutComponent,

        ContactDialogComponent,

        LoaderComponent,

        ExpertiseLocationMapComponent,

        GenericLoaderComponent,

        ImageWithLoaderComponent,

        NeedHelpComponent,

        HelpdeskDialogComponent
    ],
    imports: [
        MsalModule.forRoot({
            clientID: environment.clientID,
            authority: 'https://login.microsoftonline.com/461772ee-17f3-4cbc-b9c4-1a21b547acdc',
            cacheLocation: 'localStorage'
        }),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgReduxModule,
        DeviceDetectorModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    providers: [
        LanguageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppInsightsInterceptorService,
            multi: true
           }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(ngRedux: NgRedux<IAppState>) {
        ngRedux.provideStore(store);

    }
}
