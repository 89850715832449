import { Component, Input, OnInit } from '@angular/core';
import { DETAIL_CONTENT_HTML_IDS } from '../../../constants/detailContentHTMLId.constant';
import { Expertise } from '../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-mobile-detail-page-index-flyout',
    templateUrl: './mobile-detail-page-index-flyout.component.html',
    styleUrls: ['./mobile-detail-page-index-flyout.component.scss']
})
export class MobileDetailPageIndexFlyoutComponent implements OnInit {

    @Input() expertise: Expertise;

    showFlyoutContent = false;
    detailHTMLIds = DETAIL_CONTENT_HTML_IDS;
    detailHTMLTranslations = [
        'detailPage.info',
        'detailPage.contactInfo',
        'detailPage.competition',
        'detailPage.document',
        'detailPage.certificates'
    ];
    private detailSections: any[];
    selectedIndicator = 0;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.detailSections = this.detailHTMLIds.map(id => {
                return document.getElementById(id);
            });
        }, 200);

        const detailContentSection = document.getElementById('detail-wrapper');
        detailContentSection.onscroll = (e) => {
            const offset = detailContentSection.scrollTop + window.innerHeight / 3;
            for (let i = 0; i < this.detailSections.length; i++) {
                if (this.detailSections[i].offsetTop < offset && this.detailSections[i + 1].offsetTop > offset) {
                    this.selectedIndicator = i;
                    break;
                }
            }
        };
    }

    scrollToItem = (id: string) => {
        const elem = document.getElementById(id);
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.showFlyoutContent = false;
    };

}
