import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

export class ApiUtil {

    public static getHeaders = (idtoken : string): HttpHeaders => {
       return new HttpHeaders()
            .append('Authorization', 'Basic ' + environment.bto)
            .append('idtoken', idtoken)
            .append('Content-Type', 'application/json');
    };
}
