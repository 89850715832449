import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Message } from 'src/app/domain/message/message.domain';
import { HelpdeskService } from 'src/app/services/helpdesk/helpdesk.service';
import { Observable, empty } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/catch';

@Component({
    selector: 'app-need-help',
    templateUrl: './need-help.component.html',
    styleUrls: ['./need-help.component.css']
})
export class NeedHelpComponent implements OnInit, OnDestroy {

    success = true;
    sent = false;
    sending = false;
    showError = false;

    constructor(private _helpdeskSvc: HelpdeskService) { }

    @Input() dialogOpen = false;
    @Output() dialogClosed = new EventEmitter();
    message = new Message();

    //id from helpdesk-dialog component
    @Input() typeId: number;
    errorMsg: string;

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.unloadFunction);
    }

    ngOnInit() {
        this.message.emailCompany = '';

        window.addEventListener('beforeunload', this.unloadFunction);
    }

    private unloadFunction = (e) => {
        if ((!this.message.submitterName.length && !this.message.emailAddress.length)) {
            return undefined;
        }
    };

    backDropClicked = () => {
        this.sent = false;
        this.dialogClosed.emit();
    }

    askHelpdesk = () => {
        this.showError = false;

        if ((!this.message.submitterName.length || !this.message.emailAddress.length)) {
            return this.showError = true;
        }

        this.sending = true;

        if (this.typeId == 0) {
            this.message.subject = "OSS How To Login";

            this.sendMessage();
        }
        else if (this.typeId == 1) {
            this.message.subject = "OSS Reset Password";

            this.sendMessage();
        }
    }

    sendMessage() {

        this._helpdeskSvc.askHelpdesk(this.message, this.typeId).then(response => {

            this.sent = true;
            this.sending = false;
            this.success = true;
        }).catch(e => {

            console.log(e.innererror[Object.keys(e.innererror)[0]]);

            //display the 'message' from 'innererror'
            this.errorMsg = e.innererror[Object.keys(e.innererror)[0]];
            this.sent = true;
            this.sending = false;
            this.success = false;
        });
    }
}
