import { Component, OnInit } from '@angular/core';
import { IAppState } from '../../../../../store/IAppState';
import { NgRedux, select } from '@angular-redux/store';
import { FILTER_ACTIONS } from '../../../../../store/reducers/filter/filter.actions';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-client-type-filter',
    templateUrl: './client-type-filter.component.html',
    styleUrls: ['./client-type-filter.component.scss']
})
export class ClientTypeFilterComponent implements OnInit {

    constructor(private _ngRedux: NgRedux<IAppState>) {
    }

    @select(['filterReducer', 'selectedClientTypeFilters']) selectedClientTypeFilters: Observable<string[]>;

    @select(['filterReducer', 'availableClienTypeFilters']) availableClienTypeFilters: Observable<string[]>;

    private localSelectedClientTypeFilters;

    ngOnInit() {
        this.selectedClientTypeFilters.subscribe(selectedClientTypeFilter => {
                this.localSelectedClientTypeFilters = selectedClientTypeFilter;
            }
        );
    }

    isChecked = (clientType: string) => {
        return (this.localSelectedClientTypeFilters.indexOf(clientType) !== -1);
    };

    setClientTypeFilter = (filter, checked) => {
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_SELECTED_CLIENT_TYPE_FILTER, filter: filter, add: checked });
    };
}
