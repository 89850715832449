import { Component, Input, OnInit } from '@angular/core';
import { EXPERTISE_INFO_COMPONENT_TYPE } from '../../../../constants/expertiseInforComponent.constant';
import { Expertise } from '../../../../domain/expertise/expertise.domain';
import { ExpertiseAttachment } from '../../../../domain/attachment/attachment.domain';

@Component({
    selector: 'app-expertise-info',
    templateUrl: './expertise-info.component.html',
    styleUrls: ['./expertise-info.component.scss']
})
export class ExpertiseInfoComponent implements OnInit {

    constructor() {
    }

    thresholdNumber = 400;
    types = EXPERTISE_INFO_COMPONENT_TYPE;
    @Input() title: string;
    @Input() description: string;
    @Input() image: string = null;
    @Input() imageDescription: string;
    @Input() type: EXPERTISE_INFO_COMPONENT_TYPE = this.types.TEXT;
    @Input() expertise: Expertise;
    @Input() inverted = false;
    @Input() imageRemovePrint = true;
    @Input() descriptionSplitted = true;
    descriptionValue = '';
    formatter = new Intl.NumberFormat('nl-BE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    });
    private postamble = '...';

    ngOnInit() {
        if (this.description && typeof this.description === 'string' && this.description.length > (this.thresholdNumber - this.postamble.length)) {
            return this.seeLess();
        }
        this.descriptionValue = this.description;

    }

    formatCurrency = (value) => {
        return this.formatter.format(value);
    };

    seeMore = () => {
        this.descriptionValue = this.description;

    };
    seeLess = () => {
        this.descriptionValue = this.description.substr(0, (this.thresholdNumber - this.postamble.length)) + this.postamble;
    };

}
