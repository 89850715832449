// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    bto: 'YmVzaXhnYXRld2F5OlVUeXNTakU0Z2loc1l1NEs=',
    clientID: '4f4f9118-73be-45d3-b88f-7a4c8614ffdf',
    baseUrl: 'https://oss-services.azurewebsites.net/ExpertiseSP',
    appInsights: { 
        instrumentationKey: 'bda4b5a4-9e81-4b03-b50b-48369f2cb5b0' 
    },
    cookieDomain: "https://oss.besix.com/"
};


