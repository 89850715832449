import { Component, Input, OnInit } from '@angular/core';
import { Expertise } from '../../../../domain/expertise/expertise.domain';
import { MsalService } from '@azure/msal-angular';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-expertise-contact',
    templateUrl: './expertise-contact.component.html',
    styleUrls: ['./expertise-contact.component.scss']
})
export class ExpertiseContactComponent implements OnInit {

    @Input() expertise: Expertise;
    authenticated = false;
    isTablet = false;
    isDesktop = false;
    isMobile = false;

    constructor(
        private msa: MsalService,
        private deviceService: DeviceDetectorService) {
    }

    ngOnInit() {

        if (this.msa.getUser()) {
            this.authenticated = true;
        }

        this.isTablet = this.deviceService.isTablet();
        this.isDesktop = this.deviceService.isDesktop();
        this.isMobile = this.deviceService.isMobile();
    }

    getWebsiteUrl = () => {
        let url = this.expertise.website;
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }
        return url;
    };

    goToWebsite = () => {
        let url = this.expertise.website;
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }

        window.open(url, '_blank', 'height=auto, width=auto');
    }
}
