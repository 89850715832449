import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
    selector: 'app-image-with-loader',
    templateUrl: './image-with-loader.component.html',
    styleUrls: ['./image-with-loader.component.scss']
})
export class ImageWithLoaderComponent implements OnInit {

    constructor() {
    }

    @Input() url: string;
    loading = true;
    placeholder = false;

    ngOnInit() {
        if (!this.url || !this.url.length) {
            this.showPlaceholder();
        } else {
            this.url = new URL(this.url).toString();
        }
        
        this.imgEvent();
    }

    private showPlaceholder = () => {
        this.loading = false;
        this.url = '/assets/images/placeholder.png';
        this.placeholder = true;
    };

    private imgEvent = () => {

        const img: any = new Image();
        if (img.addEventListener) {
            img.src = this.url;
            img.addEventListener('load', () => {
                this.loading = false;
            }, false);

            img.addEventListener('error', (e, x, y) => {
                this.showPlaceholder();
            });

        } else if (img.attachEvent) {
            img.setAttribute('src', this.url);
            img['attachEvent']('onload', () => {
                this.loading = false;
            }, false);
            img['attachEvent']('onerror', () => {
                this.showPlaceholder();
            }, false);
        }
    }

    //Event when resizing browser to check the width
    @HostListener('window:resize', ['$event'])
    onresize(event)
    {
        //console.log('width: ' + window.innerWidth);
    }
}
