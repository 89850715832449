import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { NAVIGATION_KEYS } from './constants/navigation.constant';
import { ResultsPageComponent } from './pages/results-page/results-page.component';
import { DetailPageComponent } from './pages/detail-page/detail-page.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    // { path: '**', component: SearchPageComponent },
    { path: NAVIGATION_KEYS.home, component: SearchPageComponent },
    { path: NAVIGATION_KEYS.results, component: ResultsPageComponent },
    { path: NAVIGATION_KEYS.detail, component: DetailPageComponent }
    //{ path: NAVIGATION_KEYS.results, component: ResultsPageComponent, canActivate : [MsalGuard] },
    //{ path: NAVIGATION_KEYS.detail, component: DetailPageComponent, canActivate : [MsalGuard] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
