import { SEARCH_ACTIONS } from './search.actions';
import { Expertise } from '../../../app/domain/expertise/expertise.domain';

export interface ISearchState {
    expertises: Expertise[];
    categories: string[];
    activityUnits: string[];
    searchResults: Expertise[];
    searchQuery: string;
    searchCategory: string;
    searchActivityUnit: string;

}

export function searchReducer(state = SEARCH_INITIAL_STATE, action): ISearchState {
    switch (action.type) {
        case SEARCH_ACTIONS.SET_SEARCH_RESULTS:
            return setSearchResults(state, action);
        case SEARCH_ACTIONS.SET_SEARCH_QUERY:
            return setSearchQuery(state, action);
        case SEARCH_ACTIONS.SET_ALL_EXPERTISES:
            return setExpertises(state, action);
        case SEARCH_ACTIONS.SET_SEARCH_CATEGORY:
            return setSearchCategory(state, action);
        case SEARCH_ACTIONS.SET_SEARCH_ACTIVITY_UNIT:
            return setSearchActivityUnit(state, action);
        case SEARCH_ACTIONS.SET_ALL_CATEGORIES:
            return setAllCategories(state, action);
        case SEARCH_ACTIONS.SET_ALL_ACTIVITY_UNITS:
            return setAllActivityUnits(state, action);
        case SEARCH_ACTIONS.RESET_SEARCH_QUERY:
            return resetSearchQuery(state, action);
    }

    return state;
}

function setSearchActivityUnit(state, action) {
    return Object.assign({}, state, { searchActivityUnit: action.searchActivityUnit });
}

function setAllActivityUnits(state, action) {
    return Object.assign({}, state, { activityUnits: action.activityUnits });
}

function setAllCategories(state, action) {
    return Object.assign({}, state, { categories: action.categories });
}

function setSearchCategory(state, action) {
    return Object.assign({}, state, { searchCategory: action.category });
}

function setExpertises(state, action) {
    return Object.assign({}, state, { expertises: action.expertises });
}

function setSearchQuery(state, action) {
    console.log(action);
    return Object.assign({}, state, { searchQuery: action.query });
}

function resetSearchQuery(state, action) {
    return Object.assign({}, state, { searchQuery: null });
}

function setSearchResults(state, action): ISearchState {
    return Object.assign({}, state, { searchResults: action.expertises });
}

export const SEARCH_INITIAL_STATE: ISearchState = {
    searchResults: [],
    searchQuery: '',
    searchCategory: '',
    expertises: [],
    categories: [],
    activityUnits: [],
    searchActivityUnit: ''

};
