import { Injectable } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { ExpertiseService } from '../expertise/expertise.service';
import { Expertise } from '../../domain/expertise/expertise.domain';
import { NavigationService } from '../navigation/navigation.service';
import { NAVIGATION_KEYS } from '../../constants/navigation.constant';
import { IAppState } from '../../../store/IAppState';
import { SEARCH_ACTIONS } from '../../../store/reducers/search/search.actions';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    @select(['searchReducer', 'searchQuery']) searchQuery;
    @select(['searchReducer', 'searchCategory']) searchCategory;
    @select(['searchReducer', 'searchActivityUnit']) searchActivityUnit;
    private localSearchQuery: string;
    private localCategory: string;
    private localActivityUnit: string;

    constructor(private _expertiseService: ExpertiseService,
                private _navigationService: NavigationService,
                private _ngRedux: NgRedux<IAppState>) {
        this.searchQuery.subscribe(s => {
            this.localSearchQuery = s;
        });

        this.searchCategory.subscribe(category => {
            this.localCategory = category;
        });

        this.searchActivityUnit.subscribe(activityUnit => {
            console.log(activityUnit);
            this.localActivityUnit = activityUnit;
        });
    }

    findExpertisesBySearchQuery = (): Promise<Expertise[]> => {

        return new Promise<Expertise[]>((resolve, reject) => {
            this._expertiseService.getExpertises().then(expertises => {
                const enrichedQuery = this.enrichQuery();
                const filteredExpertises = expertises.filter(expertise => {
                    const regexp = new RegExp(enrichedQuery);
                    // add .toLowerCase() to make case insensitive
                    return (
                        // name of the expertise
                        (expertise.activityUnit != null && regexp.test(expertise.activityUnit.toLowerCase())) ||

                        // name of the company
                        (expertise.companyName != null && regexp.test(expertise.companyName.toLowerCase())) ||

                        // category
                        (expertise.oECategory != null && regexp.test(expertise.oECategory.join(' ').toLowerCase())) ||

                        // activityInformation
                        (expertise.activityInformation != null && regexp.test(expertise.activityInformation.toLowerCase())) ||

                        // tags
                        (expertise.tags != null && regexp.test(expertise.tags.toLowerCase()))
                    );

                });

                this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_RESULTS, expertises: filteredExpertises });
                this.navigateToResultPage();
                return resolve(filteredExpertises);
            }).catch(e => {
                return reject(e);
            });
        });

    };

    findExpertisesByCategory = (): Promise<Expertise[]> => {

        return new Promise<Expertise[]>((resolve, reject) => {
            this._expertiseService.getExpertises().then(expertises => {
                const searchCategory = this.localCategory;
                const filteredExpertises = expertises.filter(expertise => {
                    return (
                        new RegExp(searchCategory).test(expertise.oECategory.join(' '))
                    );

                });
                this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_RESULTS, expertises: filteredExpertises });
                this.navigateToResultPage();
                return resolve(filteredExpertises);
            }).catch(e => {
                return reject(e);
            });
        });

    };
    findExpertisesByActivityUnit = (): Promise<Expertise[]> => {

        return new Promise<Expertise[]>((resolve, reject) => {
            this._expertiseService.getExpertises().then(expertises => {
                const searchActivity = this.localActivityUnit;
                const filteredExpertises = expertises.filter(expertise => {
                    return (
                        //new RegExp(searchActivity.toLowerCase()).test(expertise.activityUnit.toLowerCase())
                        searchActivity === expertise.activityUnit   
                    );

                });
                console.log(filteredExpertises);
                this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_RESULTS, expertises: filteredExpertises });
                this.navigateToResultPage();
                return resolve(filteredExpertises);
            }).catch(e => {
                return reject(e);
            });
        });

    };

    showAllExpertises = (): Promise<Expertise[]> => {

        return new Promise<Expertise[]>((resolve, reject) => {
            this._expertiseService.getExpertises().then(expertises => {
                this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_RESULTS, expertises: expertises });
                this.navigateToResultPage();
                return resolve(expertises);
            }).catch(e => {
                return reject(e);
            });
        });
    };

    clearQuery = () => {
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_QUERY, query: '' });
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_CATEGORY, category: '' });
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_ACTIVITY_UNIT, searchActivityUnit: '' });
    };

    private navigateToResultPage = () => {
        this._navigationService.navigate(NAVIGATION_KEYS.results);
    };

    private enrichQuery = () => {
        // enrich logic
        let query = this.localSearchQuery.toLowerCase();
        query = query.replace(new RegExp(' ', 'g'), '|');
        return query;
    };

}
