import { Injectable } from '@angular/core';
import { NAVIGATION_KEYS } from '../../constants/navigation.constant';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router/src/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router) {
    }

    navigate = (route: NAVIGATION_KEYS, extras: NavigationExtras = {}) => {
        this.router.navigate([route], extras);
    };
}
