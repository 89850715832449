import { Component, Input, OnInit } from '@angular/core';
import { DETAIL_CONTENT_HTML_IDS } from '../../../constants/detailContentHTMLId.constant';
import { Expertise } from '../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-desktop-detail-page-index',
    templateUrl: './desktop-detail-page-index.component.html',
    styleUrls: ['./desktop-detail-page-index.component.scss']
})
export class DesktopDetailPageIndexComponent implements OnInit {

    detailHTMLIds = DETAIL_CONTENT_HTML_IDS;
    private detailSections: any[];
    selectedIndicator = 0;
    @Input() expertise: Expertise;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.detailSections = this.detailHTMLIds.map(id => {
                return document.getElementById(id);
            });
        }, 200);

        const detailContentSection = document.getElementById('detail-content');

        detailContentSection.onscroll = (e) => {
            const offset = detailContentSection.scrollTop + window.innerHeight / 2;
            for (let i = 0; i < this.detailSections.length; i++) {

                /* This condition is to prevent from error when (i) reach the max index */
                if (i != this.detailSections.length - 1) {
                    if (this.detailSections[i].offsetTop < offset && this.detailSections[i + 1].offsetTop > offset) {
                        this.selectedIndicator = i;
                        break;
                    }
                }
                else {

                    /* This condition is to fix the highlight when scrolling up */
                    if (this.detailSections[i].offsetTop > offset) {
                        this.selectedIndicator = 0;
                        break;
                    }
                    else {
                        this.selectedIndicator = i;
                        break;
                    }
                }
            }
        };
    };

    scrollToItem = (id: string, intId: number) => {
        const elem = document.getElementById(id);
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.selectedIndicator = intId;
    };
}
