export enum SEARCH_ACTIONS {
    SET_SEARCH_RESULTS = 'SEARCH_SET_SEARCH_RESULTS',
    SET_SEARCH_QUERY = 'SEARCH_SET_SEARCH_QUERY',
    SET_SEARCH_CATEGORY = 'SEARCH_SET_SEARCH_CATEGORY',
    SET_SEARCH_ACTIVITY_UNIT = 'SEARCH_SET_SEARCH_ACTIVITYUNIT',
    SET_ALL_CATEGORIES = 'SEARCH_SET_ALL_CATEGORIES',
    SET_ALL_EXPERTISES = 'SEARCH_SET_ALL_EXPERTISES',
    SET_ALL_ACTIVITY_UNITS = 'SEARCH_SET_ALL_ACTIVITYUNITS',
    RESET_SEARCH_QUERY = 'RESET_SEARCH_QUERY'
}
