import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Expertise } from '../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-expertise-tab',
    templateUrl: './expertise-tab.component.html',
    styleUrls: ['./expertise-tab.component.scss']
})
export class ExpertiseTabComponent implements OnInit, AfterViewInit {
    loading = true;

    @Input() expertise: Expertise;

    constructor() {
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {

    }

}
