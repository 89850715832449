export class ExpertiseAttachment {
    attachmentType: string;
    // base64StringValue: string;
    fileName: string;
    fullUrl: string;
    cDNFullUrl: string;

    constructor(data: any) {
        this.attachmentType = data.AttachmentType ? data.AttachmentType : '';
        // this.base64StringValue = data.Base64StringValue ? data.Base64StringValue : '';
        this.fileName = data.FileName ? data.FileName : '';
        this.fullUrl = data.FullUrl ? data.FullUrl : '';
        this.cDNFullUrl = data.CDNFullUrl ? data.CDNFullUrl : '';
    }
}
