import { ExpertiseAttachment } from '../attachment/attachment.domain';

export class ExpertiseProject {
    attachment: ExpertiseAttachment;
    description: string;

    constructor(data: any) {
        this.attachment = data.Image ? new ExpertiseAttachment(data.Image) : null;
        this.description = data.Description ? data.Description : null;
    }
}
