import { ExpertiseAttachment } from '../attachment/attachment.domain';
import { ExpertiseProject } from '../project/expertiseProject.domain';

export class Expertise {

    ID: number;
    companyName: string;
    activityUnit: string;
    oECategory: string[];
    communityName: string;
    country: string;
    address: string;
    latitude: string;
    longitude: string;
    percentOfTotalRevenue: number;
    activityArea: string[];
    activityInformation: string;
    appCreatedBy: string;
    appModifiedBy: string;
    certifcicateRecognitions: string;
    clientType: string[];
    companyInformationEmployees: string;
    contactMrMrs: string;
    contentType?: any;
    created: Date;
    createdBy: string;
    differentiationFromCompetitions: string;

    editorialInformationAuthorNameEmail: string;
    editorialInformationDate: string;
    emailCompany: string;
    emailContact: string;
    export: boolean;
    fax: string;
    jobTitle: string;
    jointVenture: boolean;
    maxProjectValue: string;
    minProjectValue: string;
    modified: Date;
    modifiedBy: string;
    news: string;
    opennessTowardsExportIntl: string;
    place: string;
    references: string;
    relevance: string;
    role: string;
    simularityToCompetitions: string;
    tags: string;
    telCell1: string;
    telCell2: string;
    totalRevenue: number;
    twitter: string;
    typeOfService: string;
    vatNumber: string;
    website: string;
    personorGroup?: any;

    logo: ExpertiseAttachment;
    projects: ExpertiseProject[];
    documents: ExpertiseAttachment[];

    constructor(data: any) {

        this.companyName = data.CompanyName ? data.CompanyName : null;
        this.activityUnit = data.ActivityUnit ? data.ActivityUnit : null;
        this.oECategory = data.OECategory ? data.OECategory : [];
        this.communityName = data.CommunityName ? data.CommunityName : null;
        this.country = data.Country ? data.Country : null;
        this.address = data.Address ? data.Address : null;
        this.latitude = data.Latitude ? data.Latitude : null;
        this.longitude = data.Longitude ? data.Longitude : null;
        this.percentOfTotalRevenue = data.PercentOfTotalRevenue ? data.PercentOfTotalRevenue : 0;
        this.activityArea = data.ActivityArea ? data.ActivityArea : [];
        this.activityInformation = data.ActivityInformation ? data.ActivityInformation : null;
        this.appCreatedBy = data.AppCreatedBy ? data.AppCreatedBy : null;
        this.appModifiedBy = data.AppModifiedBy ? data.AppModifiedBy : null;
        this.certifcicateRecognitions = data.CertifcicateRecognitions ? data.CertifcicateRecognitions : '';
        this.clientType = data.ClientType ? data.ClientType : [];
        this.companyInformationEmployees = data.CompanyInformationEmployees ? data.CompanyInformationEmployees : null;
        this.contactMrMrs = data.ContactMrMrs ? data.ContactMrMrs : null;
        this.contentType = data.ContentType ? data.ContentType : null;
        this.created = data.Created ? data.Created : null;
        this.createdBy = data.CreatedBy ? data.CreatedBy : null;
        this.differentiationFromCompetitions = data.DifferentiationFromCompetitions ? data.DifferentiationFromCompetitions : null;

        this.editorialInformationAuthorNameEmail = data.EditorialInformationAuthorNameEmail ? data.EditorialInformationAuthorNameEmail + ' ' : null;
        this.editorialInformationDate = data.EditorialInformationDate ? data.EditorialInformationDate : null;
        this.emailCompany = data.EmailCompany ? data.EmailCompany : null;
        this.emailContact = data.EmailContact ? data.EmailContact : null;
        this.export = data.Export === 'True';
        this.fax = data.Fax ? data.Fax : null;
        this.ID = data.ID ? data.ID : null;
        this.jobTitle = data.JobTittle ? data.JobTittle : null;
        this.jointVenture = data.JointVenture === 'True';
        this.maxProjectValue = !isNaN(data.MaxProjectValue) ? data.MaxProjectValue : null;
        this.minProjectValue = !isNaN(data.MinProjectValue) ? data.MinProjectValue : null;
        this.modified = data.Modified ? data.Modified : null;
        this.modifiedBy = data.ModifiedBy ? data.ModifiedBy : null;
        this.news = data.News ? data.News : null;
        this.opennessTowardsExportIntl = data.OpennessTowardsExportIntl ? data.OpennessTowardsExportIntl : null;
        this.place = data.Place ? data.Place : null;
        this.references = data.References ? data.References : null;
        this.relevance = data.Relevance ? data.Relevance : null;
        this.role = data.Role ? data.Role : null;
        this.simularityToCompetitions = data.SimularityToCompetitions ? data.SimularityToCompetitions : null;
        this.tags = data.Tags ? data.Tags : null;
        this.telCell1 = data.TelCell1 ? data.TelCell1 : null;
        this.telCell2 = data.TelCell2 ? data.TelCell2 : null;
        this.totalRevenue = data.TotalRevenue ? data.TotalRevenue : 0;
        this.twitter = data.Twitter ? data.Twitter : null;
        this.typeOfService = data.TypeOfService ? data.TypeOfService : null;
        this.vatNumber = data.VatNumber ? data.VatNumber : null;
        this.website = data.Website ? data.Website : null;
        this.personorGroup = data.PersonorGroup ? data.PersonorGroup : null;

        // LOGO
        this.logo = data.Logo ? new ExpertiseAttachment(data.Logo) : null;
        // ATTACHMENTS
        this.projects = data.Images ? data.Images.map(image => new ExpertiseProject(image)) : [];
        // DOCUMENTS
        this.documents = data.Documents ? data.Documents.map(document => new ExpertiseAttachment(document)) : [];
    }

}
