import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppInsightsService } from './app-insights.service';

@Injectable()
export class AppInsightsInterceptorService implements HttpInterceptor {
 
  constructor(private appInsightsService: AppInsightsService) {}
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.appInsightsService.logEvent(req.url, req.body);
    return next.handle(req);
  }
}
