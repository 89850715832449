import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

    constructor() {
    }

    @Output() checkValueChanged = new EventEmitter<boolean>();
    @Input() title: string;
    @Input() checked = false;

    ngOnInit() {
    }

    checkClicked = () => {
        this.checked = !this.checked;
        this.checkValueChanged.emit(this.checked);
    };
}
