import { Component, Input, OnInit } from '@angular/core';
import { Expertise } from '../../../../domain/expertise/expertise.domain';

@Component({
    selector: 'app-expertise-competition',
    templateUrl: './expertise-competition.component.html',
    styleUrls: ['./expertise-competition.component.scss']
})
export class ExpertiseCompetitionComponent implements OnInit {
    @Input() expertise: Expertise;

    constructor() {
    }

    ngOnInit() {
    }

}
