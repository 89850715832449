import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-helpdesk-dialog',
  templateUrl: './helpdesk-dialog.component.html',
  styleUrls: ['./helpdesk-dialog.component.scss']
})
export class HelpdeskDialogComponent implements OnInit, OnDestroy {

    constructor() { }

    @Input() dialogOpen = false;
    @Output() dialogClosed = new EventEmitter();
    openHelp = false;
    typeId: number;

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.unloadFunction);
    }

    ngOnInit() {
        window.addEventListener('beforeunload', this.unloadFunction);
    }

    private unloadFunction = (e) => {
      
    };

    backDropClicked = () => {
        this.dialogClosed.emit();
    }

}
