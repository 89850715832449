import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExpertiseService } from '../../services/expertise/expertise.service';
import { Expertise } from '../../domain/expertise/expertise.domain';
import { EXPERTISE_INFO_COMPONENT_TYPE } from '../../constants/expertiseInforComponent.constant';
import { DETAIL_CONTENT_HTML_IDS } from '../../constants/detailContentHTMLId.constant';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ExpertiseAttachmentService } from '../../services/attachment/expertise-attachment.service';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-detail-page',
    templateUrl: './detail-page.component.html',
    styleUrls: ['./detail-page.component.scss']
})
export class DetailPageComponent implements OnInit {

    public static expertiseId = 'expertiseId';

    expertise: Expertise;
    infoTypes = EXPERTISE_INFO_COMPONENT_TYPE;
    detailHTMLIds = DETAIL_CONTENT_HTML_IDS;
    contactOpen = false;
    authenticated = false;

    constructor(private activatedRoute: ActivatedRoute,
                private _expertiseService: ExpertiseService,
                private _translateService: TranslateService,
                private _attachmentService: ExpertiseAttachmentService,
                private msa: MsalService ) {

    }

    ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe(params => {
            if (params.has(DetailPageComponent.expertiseId)) {
                this.getExpertise(+params.get(DetailPageComponent.expertiseId))
                    .catch(e => {
                        console.log(e);
                    });
            }
        });

        if (this.msa.getUser()) {
            this.authenticated = true;
        }
    }

    private getExpertise = (id: number): Promise<void> => {

        return new Promise<void>((resolve, reject) => {
            this._expertiseService.getExpertiseById(id)
                .then(expertise => {
                    this.expertise = expertise;
                    console.log(expertise);
                    return resolve();
                }).catch(reject);
        });

    };

    openWebsite = () => {
        let url = this.expertise.website;
        if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank');
    };

    getLastEdited = (): string => {
        return `${this._translateService.instant('detailPage.lastEdit') +
        this.expertise.modifiedBy + ' ' +
        this._translateService.instant('general.on') + ' ' +
        moment(this.expertise.modified).format('DD/MM/YYYY').toString()}`;
    };

    signIn = () => {
        this.msa.loginRedirect();
    }
}
