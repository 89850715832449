import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Message } from '../../../domain/message/message.domain';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message/message.service';

@Component({
    selector: 'app-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit, OnDestroy {

    success = true;
    sent = false;
    showError = false;
    sending = false;

    constructor(private _messageService: MessageService) {
    }

    @Input() dialogOpen = false;
    @Input() expertiseId: number;
    @Output() dialogClosed = new EventEmitter();
    message = new Message();

    ngOnDestroy() {
        window.removeEventListener('beforeunload', this.unloadFunction);
    }

    ngOnInit() {
        if (!environment.production) {
            this.message.emailCompany = 'jeroen.brock@endare.com';
        }

        window.addEventListener('beforeunload', this.unloadFunction);
    }

    private unloadFunction = (e) => {
        if ((!this.message.message.length && !this.message.emailAddress.length && !this.message.submitterName.length && !this.message.subject)) {
            return undefined;
        }

        const confirmationMessage = 'It looks like you have been editing something. '
            + 'If you leave before saving, your changes will be lost.';

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    };
    backDropClicked = () => {
        this.sent = false;
        this.dialogClosed.emit();
    };
    sendMessage = () => {
        this.showError = false;

        if ((!this.message.message.length || !this.message.emailAddress.length || !this.message.submitterName.length || !this.message.subject)) {
            return this.showError = true;
        }

        this.sending = true;
        
        this._messageService.sendMessage(this.message, this.expertiseId).then(() => {
            this.sent = true;
            this.sending = false;
            this.success = true;
        }).catch(e => {
            this.sent = true;
            this.sending = false;
            this.success = false;
        });
    };

}
