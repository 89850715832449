import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

    @Input() errorVisible = false;
    @Output() tryAgainEvent = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

}
