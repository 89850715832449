import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Message } from 'src/app/domain/message/message.domain';
import { ApiUtil } from 'src/utils/api.util';
import { environment } from 'src/environments/environment';
import { sprintf } from 'sprintf-js';
import { API_ENDPOINTS } from 'src/app/constants/endpoints';
import { MsalService } from '@azure/msal-angular';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService {

    constructor(private http: HttpClient,
        private msa: MsalService) { }

        getUserIdToken = (): string => 
        { 
            if (!isNullOrUndefined(this.msa.getUser()))
            {    
                return JSON.stringify(this.msa.getUser().idToken);
            }
            else
            {
                return "";
            }
        }
    
        //type id = 0 and 1
    askHelpdesk = (message: Message, typeId: number): Promise<void> => {

        return new Promise((resolve, reject) => {
            const url = environment.baseUrl + sprintf(API_ENDPOINTS.helpDesk, typeId.toString());
            var header = this.getUserIdToken();

            this.http.post(url, { Message: message.getMessageJSON() }, { headers: ApiUtil.getHeaders(header) }).subscribe((response => {
               
                return resolve();
            }), (e => {
                
                console.log(e.error[Object.keys(e.error)[0]]);

                //return the 'error' from 'httpErrorResponse'
                return reject(e.error[Object.keys(e.error)[0]]);
            }));
        });
    }
}
