import { Component, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { NAVIGATION_KEYS } from '../../../constants/navigation.constant';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LanguageService } from '../../../services/language/language.service';
import { ExpertiseService } from '../../../services/expertise/expertise.service';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Navigation, EventEmitter } from 'selenium-webdriver';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html?v=${new Date().toISOString().substr(0,13)}',
    styleUrls: ['./header.component.scss?v=${new Date().toISOString().substr(0,13)}']
})
export class HeaderComponent implements OnInit, OnDestroy {

    window = window;
    @Input() backButtonEnabled = false;
    @Input() printPageButtonEnabled = false;
    authenticated = false;
    onDetailPage = false;
    onResultPage = false;
    openHelpDialog = false;
    onHomePage = false;

    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    constructor(private _navigationService: NavigationService,
                private msa: MsalService,
                private router: Router,
                private _languageService: LanguageService,
                private _expertiseService: ExpertiseService,
                private _translateService: TranslateService,
                private _cookieService: NgcCookieConsentService) {

                    this.cookieProcessStart();
    }

    ngOnInit() {
        var jwthelper = new JwtHelperService();

        if(this.msa.getUser()) {
            var myStorage = window.localStorage;
            var msalidtoken = myStorage.getItem("msal.idtoken");            
            var isexpired = jwthelper.isTokenExpired(msalidtoken);     
            if(isexpired)
            {
                this.msa.loginRedirect();
            }
            else
            {
                this.authenticated = true;
            }
        }

        this.router.events.subscribe(e => {
            this.onDetailPage = this.router.url.split('?')[0].includes(NAVIGATION_KEYS.detail);
            this.onResultPage = this.router.url.split('?')[0].includes(NAVIGATION_KEYS.results);
            this.onHomePage = this.router.url.split('?')[0].includes(NAVIGATION_KEYS.home);
        });

        localStorage.setItem("cookiesconsent_on", "no");
    }

    ngOnDestroy() {

        this.cookieProcessEnd();
    }

    retryAuthentication = () => {
        this.msa.loginRedirect();
    };

    logout = () => {
        this.msa.logout();
    }

    navigateHome = () => {
        this._navigationService.navigate(NAVIGATION_KEYS.home);
        setTimeout(() => { this.selectedLanguage(localStorage.getItem("oss_language")) }, 500);
    };
    backClicked = () => {
        this._navigationService.navigate(NAVIGATION_KEYS.results);
    };

    translate = (language: string) => {
        this._languageService.setLanguage(language);
        this._expertiseService.getExpertises();
        this.selectedLanguage(language);
    }

    selectedLanguage = (language: string) => {

        var en = document.getElementById("EN_translator");
        var fr = document.getElementById("FR_translator");
        var nl = document.getElementById("NL_translator");

        switch (language)
        {
            case "EN":
                en.setAttribute("style", "text-decoration: underline");
                fr.setAttribute("style", "text-decoration: none");
                nl.setAttribute("style", "text-decoration: none");
                break;

            case "FR":
                en.setAttribute("style", "text-decoration: none");
                fr.setAttribute("style", "text-decoration: underline");
                nl.setAttribute("style", "text-decoration: none");
                break;

            case "NL":
                en.setAttribute("style", "text-decoration: none");
                fr.setAttribute("style", "text-decoration: none");
                nl.setAttribute("style", "text-decoration: underline");
                break;
        }
    }

    cookieProcessStart = () => {

        // subscribe to cookieconsent observables to react to main events
        
        this.popupOpenSubscription = this._cookieService.popupOpen$.subscribe(() => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
              this.selectedLanguage(localStorage.getItem("oss_language"));
        });
       
        this.popupCloseSubscription = this._cookieService.popupClose$.subscribe(() => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
              localStorage.setItem("cookiesconsent_on", "yes");
        });
       
        this.initializeSubscription = this._cookieService.initialize$.subscribe((event: NgcInitializeEvent) => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
        });
       
        this.statusChangeSubscription = this._cookieService.statusChange$.subscribe((event: NgcStatusChangeEvent) => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
        });
       
        this.revokeChoiceSubscription = this._cookieService.revokeChoice$.subscribe(() => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
        });
       
        this.noCookieLawSubscription = this._cookieService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => 
        {
              // you can use this.cookieService.getConfig() to do stuff...
        });
    }

    cookieProcessEnd = () => {

        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
}
