import { Component, Input, OnInit } from '@angular/core';
import { FILTER_TYPE } from '../../../constants/filterTypes.constant';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/IAppState';

@Component({
    selector: 'app-filter-spinner',
    templateUrl: './filter-spinner.component.html',
    styleUrls: ['./filter-spinner.component.scss']
})
export class FilterSpinnerComponent implements OnInit {

    constructor(private _ngRedux: NgRedux<IAppState>) {
    }

    showFilterContent = false;
    filterTypes = FILTER_TYPE;
    @Input() filterType: FILTER_TYPE;

    budgetFilterMin = 0;
    budgetFilterMax = 0;

    ngOnInit() {
        window.addEventListener('click', () => {
            this.showFilterContent = false;
        });
    }

    click = () => {
        const lastState = this.showFilterContent;
        setTimeout(() => {
            this.showFilterContent = !lastState;
        }, 100);
    };

}
