import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Budget } from '../../../../domain/budget/budget.domain';
import { FILTER_ACTIONS } from '../../../../../store/reducers/filter/filter.actions';
import { IAppState } from '../../../../../store/IAppState';
import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { FilterService } from '../../../../services/filter/filter.service';

@Component({
    selector: 'app-budget-picker',
    templateUrl: './budget-picker.component.html',
    styleUrls: ['./budget-picker.component.scss']
})
export class BudgetPickerComponent implements OnInit {

    constructor(private _ngRedux: NgRedux<IAppState>,
                private _filterService: FilterService) {
    }

    @Input() budget: Budget;
    @select(['filterReducer', 'selectedBudgetFilter']) selectedBudgetFilter: Observable<{ min: number, max: number }>;
    @select(['filterReducer', 'availbleBudgetFilter']) availbleBudgetFilter: Observable<{ min: number, max: number }>;
    @Output() budgetChangedEmitter = new EventEmitter<Budget>();

    ngOnInit() {
        this.budget = this._filterService.budgetFilter;
    }

    setBudgetFilter = () => {
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_SELECTED_BUDGET_FILTER, filter: this.budget });
    };
    reset = () => {
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_SELECTED_BUDGET_FILTER });
        this.budget = this._filterService.budgetFilter;
    };

}
