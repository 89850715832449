import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../../../store/IAppState';
import { FILTER_ACTIONS } from '../../../../../store/reducers/filter/filter.actions';

@Component({
    selector: 'app-area-filter',
    templateUrl: './area-filter.component.html',
    styleUrls: ['./area-filter.component.scss']
})
export class AreaFilterComponent implements OnInit {

    @select(['filterReducer', 'availableAreaFilterOptions']) availableAreaFilterOptions: Observable<string[]>;
    @select(['filterReducer', 'selectedAreaFilters']) selectedAreaFilters: Observable<string[]>;

    private localSelectedFilters: string[];

    constructor(private _ngRedux: NgRedux<IAppState>) {
    }

    ngOnInit() {
        this.selectedAreaFilters.subscribe(selectedFilters => {
            this.localSelectedFilters = selectedFilters;
        });
    }

    isChecked = (area: string) => {
        return (this.localSelectedFilters.indexOf(area) !== -1);
    };
    setAreaFilter = (filter, checked) => {
        this._ngRedux.dispatch({ type: FILTER_ACTIONS.SET_SELECTED_AREA_FILTER, filter: filter, add: checked });
    };
}
