import { Injectable } from '@angular/core';
import { Message } from '../../domain/message/message.domain';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { API_ENDPOINTS } from '../../constants/endpoints';
import { sprintf } from 'sprintf-js';
import { ApiUtil } from '../../../utils/api.util';
import { MsalService } from '@azure/msal-angular';
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private http: HttpClient,
        private msa: MsalService) {
    }

    getUserIdToken = (): string => 
        { 
            if (!isNullOrUndefined(this.msa.getUser()))
            {    
                return JSON.stringify(this.msa.getUser().idToken);
            }
            else
            {
                return "";
            }
        }

    sendMessage = (message: Message, expertiseId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            const url = environment.baseUrl + sprintf(API_ENDPOINTS.postMessage, expertiseId.toString());
            var header = this.getUserIdToken();
            this.http.post(url, { Message: message.getMessageJSON() }, { headers: ApiUtil.getHeaders(header) }).subscribe((response => {
                return resolve();
            }), (error => {
                console.log(error);
                return reject();
            }));
        });
    };
}
