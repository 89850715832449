export const STATIC_CATEGORIES = [
    'Concessions & assets',
    'Engineering',
    'Foundation works',
    'Building materials',
    'Underground structure',
    'General contractor',
    'Structure works',
    'MEP',
    'Facility management',
    'Real estate',
    'Finishing'
];
