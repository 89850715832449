import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mobile-filter-flyout',
    templateUrl: './mobile-filter-flyout.component.html',
    styleUrls: ['./mobile-filter-flyout.component.scss']
})
export class MobileFilterFlyoutComponent implements OnInit {

    showFlyoutContent = false;

    constructor() {
    }

    ngOnInit() {
    }

}
