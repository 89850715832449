import { createStore } from 'redux';
import { IAppState } from './IAppState';
import { rootReducer } from './reducers/rootReducer';

declare let window: any;

const devToolsExtension = (window.devToolsExtension) ? window.devToolsExtension() : (f) => f;

// export const store = createStore<IAppState>(rootReducer, compose(devToolsExtension) as GenericStoreEnhancer);
export const store = createStore<IAppState, null, {}, {}>(rootReducer);
