import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-category-search-tab',
    templateUrl: './category-search-tab.component.html',
    styleUrls: ['./category-search-tab.component.scss']
})
export class CategorySearchTabComponent implements OnInit {

    constructor() {
    }

    @Input() categoryName: string;

    ngOnInit() {
    }

}
