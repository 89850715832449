import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { API_ENDPOINTS } from '../../constants/endpoints';
import { environment } from '../../../environments/environment';
import { ApiUtil } from '../../../utils/api.util';
import { ExpertiseAttachment } from '../../domain/attachment/attachment.domain';

@Injectable({
    providedIn: 'root'
})
export class ExpertiseAttachmentService {

    constructor(private http: HttpClient) {
    }

    // getExpertiseAttachmentsById = (expertiseId: Number): Promise<{ images: ExpertiseAttachment[], documents: ExpertiseAttachment[] }> => {
    //     return new Promise((resolve, reject) => {
    //         const url = environment.baseUrl + sprintf(API_ENDPOINTS.getImages, expertiseId.toString());
    //
    //         this.http.get(url, { headers: ApiUtil.getHeaders() }).subscribe(((response: any) => {
    //             let images = [];
    //             let documents = [];
    //             if (response.value && response.value.length) {
    //                 images = response.value.map(image => {
    //
    //                     if (image.AttachmentType === 'Image') {
    //                         // image.Base64StringValue = 'data:image/jpg;base64,' + image.Base64StringValue;
    //                         return new ExpertiseAttachment(image);
    //                     }
    //
    //                 }).filter(Boolean);
    //                 documents = response.value.map(document => {
    //
    //                     if (document.AttachmentType === 'pdf') {
    //                         return new ExpertiseAttachment(document);
    //                     }
    //
    //                 }).filter(Boolean);
    //             }
    //             return resolve({ images, documents });
    //         }), (error => {
    //             console.log(error);
    //             return reject(error);
    //         }));
    //     });
    //
    // };

}
