export class Budget {

    min: number;
    max: number;

    constructor(data: any = {}) {
        this.min = data.min ? data.min : 0;
        this.max = data.max ? data.max : Infinity;
    }
}
