import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Expertise } from '../../domain/expertise/expertise.domain';
import { environment } from '../../../environments/environment';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../store/IAppState';
import { SEARCH_ACTIONS } from '../../../store/reducers/search/search.actions';
import { Observable } from 'rxjs';
import { ApiUtil } from '../../../utils/api.util';
import { MsalService } from '@azure/msal-angular';
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root'
})
export class ExpertiseService {

    @select(['searchReducer', 'expertises']) expertises: Observable<Expertise[]>;

    private localExpertises: Expertise[];
    selectedLanguage: String;

    constructor(private httpClient: HttpClient,
                private msa: MsalService,
                private _ngRedux: NgRedux<IAppState>) {

        this.expertises.subscribe(exp => {
            //console.log(exp);
            this.localExpertises = exp;
        });
    }

    getUserIdToken = (): string => 
        { 
            if (!isNullOrUndefined(this.msa.getUser()))
            {    
                return JSON.stringify(this.msa.getUser().idToken);
            }
            else
            {
                return "";
            }
        }

    getExpertises = (): Promise<Expertise[]> => {
        return new Promise<Expertise[]>((resolve, reject) => {

            /* if (this.localExpertises && this.localExpertises.length) {
                return resolve(this.localExpertises);
            } */

            this.selectedLanguage = window.localStorage.getItem('oss_language');

            const header = this.getUserIdToken();
            const url = environment.baseUrl + '?language=' + this.selectedLanguage;

            this.httpClient.get(url, { headers: ApiUtil.getHeaders(header), withCredentials: true }).subscribe((obs: any) => {
                if (obs.value) {
                    const expertises = obs.value.map(expertise => new Expertise(expertise)).sort((a: Expertise, b: Expertise) => {
                        const textA = a.companyName.toUpperCase();
                        const textB = b.companyName.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_ALL_EXPERTISES, expertises: expertises });
                    // // todo: remove later
                    this.generateCategories(expertises);
                    this.generateActivityUnits(expertises);
                    // this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_SEARCH_RESULTS, expertises: expertises });
                    return resolve(expertises);
                }
            }, e => {
                console.log(e);
                return reject(e);
            });
        });
    };

    getExpertiseById = (ID: number): Promise<Expertise> => {
        return new Promise<Expertise>((resolve, reject) => {
            const subscription = this.expertises.subscribe(exp => {
                if (exp.length) {
                    const foundExpertise = exp.find(expertise => expertise.ID === ID);
                    foundExpertise ? resolve(foundExpertise) : reject('not found');
                    return;
                }
            });
        });
    };

    private generateCategories = (expertises: Expertise[]) => {

        let categories: string[] = [];
        for (const expertise of expertises) {
            categories = categories.concat(expertise.oECategory
                .map(category => category.trim()))
                .filter((el) => el.length)
                .filter((el, i, a) => i === a.indexOf(el))
                .sort();
        }
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_ALL_CATEGORIES, categories: categories });
    };

    private generateActivityUnits = (expertises: Expertise[]) => {
        let activityUnits: string[] = [];
        expertises.forEach(exp => {
            return activityUnits.push(exp.activityUnit);
        });
        activityUnits = activityUnits
            .map(unit => unit.trim())
            .filter((el) => el.length)
            .filter((el, i, a) => i === a.indexOf(el))
            .sort();
        this._ngRedux.dispatch({ type: SEARCH_ACTIONS.SET_ALL_ACTIVITY_UNITS, activityUnits: activityUnits });
    };
}
