import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-generic-loader',
    templateUrl: './generic-loader.component.html',
    styleUrls: ['./generic-loader.component.scss']
})
export class GenericLoaderComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
